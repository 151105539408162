<template>
  <el-card id="contract">
    <header>
      <!-- <h1>合同管理--新版本</h1> -->
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司名称</span>
            <el-input
              v-model="companyName"
              placeholder="请输入公司名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>行业类型</span>
            <el-select
              v-model="type"
              placeholder="请选择行业类型"
              filterable
              size="small"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>业务类型</span>
            <el-select
              v-model="businessType"
              placeholder="请选择业务类型"
              filterable
              size="small"
            >
              <el-option
                v-for="item in businessTypeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>创建时间</span>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @row-dblclick="dblclick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="companyName"
          label="公司名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="行业类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createUserName"
          label="创建人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="联系人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="telephone"
          label="电话"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="businessHandling"
          label="产品类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="businessType"
          label="业务类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="routeType"
          label="线路类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="numberFee"
          label="号码费"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="numberNum"
          label="号码数量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="combo"
          label="套餐(月)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seatsNumber"
          label="坐席数量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rateWithin"
          label="套餐内费率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rateExtra"
          label="套餐外费率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="comboRecharge"
          label="套餐外充值"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cooperationTime"
          label="合作周期(月)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="featureFee"
          label="功能费"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="feeTotal"
          label="费用合计"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="presented"
          label="赠送"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="installment"
          label="是否分期"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarke"
          label="备注"
          align="center"
        ></el-table-column>

        <el-table-column prop="auditStatus" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.auditStatus == 1">审核中</span>
            <span v-if="scope.row.auditStatus == 2">审核通过</span>
            <span v-if="scope.row.auditStatus == 3">审核未通过</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="small"
              >编辑</el-button
            >
            <el-button type="danger" @click="del(scope)" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 编辑合同弹出框 -->
    <el-dialog title="编辑合同" :visible.sync="updateVisible" width="70%">
      <el-form ref="sjyForm" :model="sjyForm" label-width="100px">
        <div class="contract">
          <el-form-item label="公司名称">
            <el-input
              v-model="sjyForm.companyName"
              placeholder="请输入公司名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="行业类型">
            <el-select
              v-model="sjyForm.type"
              placeholder="请选择行业类型"
              filterable
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input
              v-model="sjyForm.phone"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="sjyForm.telephone"
              placeholder="请输入电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品类型">
            <el-select
              v-model="sjyForm.businessHandling"
              placeholder="请选择产品类型"
              filterable
            >
              <el-option
                v-for="item in productTypeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select
              v-model="sjyForm.businessType"
              placeholder="请选择业务类型"
              filterable
            >
              <el-option
                v-for="item in businessTypeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线路类型">
            <el-select
              v-model="sjyForm.routeType"
              placeholder="请选择线路类型"
              filterable
            >
              <el-option
                v-for="item in circuitTypeList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="号码费">
            <el-input
              v-model="sjyForm.numberFee"
              placeholder="请输入号码费"
            ></el-input>
          </el-form-item>
          <el-form-item label="号码数量">
            <el-input
              v-model="sjyForm.numberNum"
              placeholder="请输入号码数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="套餐(月)">
            <el-input
              v-model="sjyForm.combo"
              placeholder="请输入套餐(月)"
            ></el-input>
          </el-form-item>
          <el-form-item label="坐席数量">
            <el-input
              v-model="sjyForm.seatsNumber"
              placeholder="请输入坐席数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="套餐内费率">
            <el-input
              v-model="sjyForm.rateWithin"
              placeholder="请输入套餐内费率"
            ></el-input>
          </el-form-item>
          <el-form-item label="套餐外费率">
            <el-input
              v-model="sjyForm.rateExtra"
              placeholder="请输入套餐外费率"
            ></el-input>
          </el-form-item>
          <el-form-item label="合作周期(月)">
            <el-input
              v-model="sjyForm.cooperationTime"
              placeholder="请输入合作周期(月)"
            ></el-input>
          </el-form-item>
          <el-form-item label="套餐外充值">
            <el-input
              v-model="sjyForm.comboRecharge"
              placeholder="请输入套餐外充值"
            ></el-input>
          </el-form-item>
          <el-form-item label="功能费">
            <el-input
              v-model="sjyForm.featureFee"
              placeholder="请输入功能费"
            ></el-input>
          </el-form-item>
          <el-form-item label="费用合计">
            <el-input
              v-model="sjyForm.feeTotal"
              placeholder="请输入费用合计"
            ></el-input>
          </el-form-item>
          <el-form-item label="赠送">
            <el-input
              v-model="sjyForm.presented"
              placeholder="请输入赠送"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否分期">
            <el-radio-group v-model="sjyForm.installment">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="sjyForm.remarke"
            placeholder="备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog title="审批 & 进度" :visible.sync="dblclickVisible">
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="colorPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="auditRemark"
              v-if="v.status == 2 && planId == getId"
            >
            </el-input>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              v-else
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="btns">
        <!-- <el-button type="success" @click="productSearch" style="width: 49%"
          >点击查看产品信息</el-button
        > -->
        <el-button type="primary" @click="planSubmit" style="width: 100%"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  shangjiContractShangjiSelect,
  shangjiContractShangjiUpdate,
  shangjiContractShangjiSelectContract,
  shangjiContractShangjiUpdateContract,
  shangjiContractShangjiDelete,
} from "@/api/shangjiContract";
let token = localStorage.getItem("TOKEN");
export default {
  data() {
    return {
      typeList: [
        {
          id: 0,
          title: "外卖/生鲜/商超/电商订单配送",
        },
        {
          id: 1,
          title: "快递/物流派送",
        },
        {
          id: 2,
          title: "家政回访服务",
        },
        {
          id: 3,
          title: "会员关怀/居民服务回访/市场问卷调研/民生保障服务",
        },
        {
          id: 4,
          title: "出行服务",
        },
        {
          id: 5,
          title: "办公协作",
        },
        {
          id: 6,
          title: "政采对接",
        },
        {
          id: 7,
          title: "订单类售后服务",
        },
        {
          id: 8,
          title: "法律服务",
        },
        {
          id: 9,
          title: "广告传媒",
        },
        {
          id: 10,
          title: "生物科技",
        },
        {
          id: 11,
          title: "科研技术类",
        },
        {
          id: 12,
          title: "农林牧渔",
        },
        {
          id: 13,
          title: "包装材料类",
        },
        {
          id: 14,
          title: "服装服饰类",
        },
        {
          id: 15,
          title: "化工制造类/推广",
        },
        {
          id: 16,
          title: "一手交通运输工具类",
        },
        {
          id: 17,
          title: "软/硬件服务类",
        },
        {
          id: 18,
          title: "机械机电设备类",
        },
        {
          id: 19,
          title: "汽车维修/车检/保养等",
        },
        {
          id: 20,
          title: "电商回访类",
        },
        {
          id: 21,
          title: "人力资源回访类",
        },
        {
          id: 22,
          title: "少儿素质教育类",
        },
        {
          id: 23,
          title: "生活摄影",
        },
        {
          id: 24,
          title: "品牌招商加盟",
        },
        {
          id: 25,
          title: "园区招商",
        },
        {
          id: 26,
          title: "网站建设/网站推广",
        },
        {
          id: 27,
          title: "再生资源回收",
        },
        {
          id: 28,
          title: "体育用品",
        },
        {
          id: 29,
          title: "办公文教",
        },
        {
          id: 30,
          title: "日用百货销售",
        },
        {
          id: 31,
          title: "人身保险/车险续保",
        },
        {
          id: 32,
          title: "非房屋租赁服务",
        },
        {
          id: 33,
          title:
            "头部外卖平台/网约车平台/线上商超平台/引擎搜索平台/电商平台/音视频平台/新媒体平台/保险平台自有业务服务",
        },
        {
          id: 34,
          title: "家装建材",
        },
        {
          id: 35,
          title: "财税服务",
        },
        {
          id: 36,
          title: "知识产权",
        },
        {
          id: 37,
          title: "工商代办",
        },
        {
          id: 38,
          title: "平台引流/入驻",
        },
        {
          id: 39,
          title: "成人教育/培训类",
        },
        {
          id: 40,
          title: "房地产",
        },
        {
          id: 41,
          title: "会展/展览",
        },
        {
          id: 42,
          title: "学校招生（直招）",
        },
        {
          id: 43,
          title: "二手车/房推广",
        },
        {
          id: 44,
          title: "商铺推广",
        },
        {
          id: 45,
          title: "旅游服务",
        },
        {
          id: 46,
          title: "酒店民宿",
        },
        {
          id: 47,
          title: "K12教育培训/招生",
        },
        {
          id: 48,
          title: "少儿类兴趣培训",
        },
        {
          id: 49,
          title: "资质代办",
        },
        {
          id: 50,
          title: "钢材招商",
        },
        {
          id: 51,
          title: "企业培训",
        },
        {
          id: 52,
          title: "网站推广/加盟",
        },
        {
          id: 53,
          title: "职称评级",
        },
        {
          id: 54,
          title: "餐饮招商",
        },
      ],
      type: null,
      businessType: null,
      companyName: null,
      circuitTypeList: [
        {
          id: 0,
          title: "移动回呼",
        },
        {
          id: 1,
          title: "电信回呼",
        },
        {
          id: 2,
          title: "AXB",
        },
        {
          id: 3,
          title: "SIP",
        },
      ],
      productTypeList: [
        {
          id: 0,
          title: "SAAS",
        },
        {
          id: 1,
          title: "PASS",
        },
        {
          id: 2,
          title: "部署",
        },
      ],
      businessTypeList: [
        {
          id: 0,
          title: "新开户",
        },
        {
          id: 1,
          title: "加坐席",
        },
        {
          id: 2,
          title: "续费",
        },
        {
          id: 3,
          title: "测试",
        },
      ],
      sjyForm: [],
      createTime: null,
      discountPriceList: [
        {
          id: 0,
          title: "无",
          value: 1,
        },
        {
          id: 1,
          title: "一折",
          value: 0.1,
        },
        {
          id: 2,
          title: "二折",
          value: 0.2,
        },
        {
          id: 3,
          title: "三折",
          value: 0.3,
        },
        {
          id: 4,
          title: "四折",
          value: 0.4,
        },
        {
          id: 5,
          title: "五折",
          value: 0.5,
        },
        {
          id: 6,
          title: "六折",
          value: 0.6,
        },
        {
          id: 7,
          title: "七折",
          value: 0.7,
        },
        {
          id: 8,
          title: "八折",
          value: 0.8,
        },
        {
          id: 9,
          title: "九折",
          value: 0.9,
        },
      ],
      cpData: [],
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,
      // // // // // // // // //
      beginTime: null,
      clientNameList: [],
      userList: [],
      contractForm: {},
      updateVisible: false,
      beginDate: null,
      endDate: null,
      audit1Visible: false,
      audit2Visible: false,
      fid: "",
      renewMoney: 0,
      rejectVisibleTwo: "", // 驳回原因
      auditVisible: false,
      setAuditVisible: false,
      rejectVisible: false,
      myHeaders: {
        "X-Access-Token": token,
      },
      form: {
        phoneId: "",
        user_realname: "",
        active_phone: "",
        company: "",
        money: "",
        user_id: "",
      },
      formss: {},
      forms: {
        phoneId: "",
        remark: "",
        money: "",
        user_id: "",
      },
      id: "", // 号码id
      money: null, // 当前列数据金额
      value: null,
      status: "",
      remark: "", //备注（只传不回显）
      user_realname: "",
      active_phone: "",
      activePhone: "",
      company: "",
      roleName: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.form.company = this.$route.query.company;
    this.roleName = localStorage.getItem("roleName");
    this.user_realname = localStorage.getItem("user_realname");

    this.getId = localStorage.getItem("id");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
  },
  inject: ["reload"],
  methods: {
    // 编辑
    update(row) {
      this.sjyForm = { ...row.row };
      this.contractId = row.row.id;
      this.contractNumber = row.row.contractNumber;
      this.updateVisible = true;
    },
    // 编辑确定
    updateSubmit() {
      if (this.sjyForm.installment) {
        if (this.sjyForm.installment == "1") {
          this.sjyInstallment = "是";
        } else {
          this.sjyInstallment = "否";
        }
      }
      let params = {
        companyName: this.sjyForm.companyName,
        type: this.sjyForm.type,
        phone: this.sjyForm.phone,
        telephone: this.sjyForm.telephone,
        businessHandling: this.sjyForm.businessHandling,
        businessType: this.sjyForm.businessType,
        routeType: this.sjyForm.routeType,
        numberFee: this.sjyForm.numberFee,
        numberNum: this.sjyForm.numberNum,
        combo: this.sjyForm.combo,
        seatsNumber: this.sjyForm.seatsNumber,
        rateWithin: this.sjyForm.rateWithin,
        rateExtra: this.sjyForm.rateExtra,
        cooperationTime: this.sjyForm.cooperationTime,
        comboRecharge: this.sjyForm.comboRecharge,
        featureFee: this.sjyForm.featureFee,
        feeTotal: this.sjyForm.feeTotal,
        presented: this.sjyForm.presented,
        installment: this.sjyInstallment,
        remarke: this.sjyForm.remarke,
        contractId: this.contractId,
        contractNumber: this.contractNumber,
      };
      // console.log("params", params);
      let res = shangjiContractShangjiUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.wrapVisible = false;
          this.reload();
        } else {
          Message.error(res.data.message);
          this.wrapVisible = false;
          this.reload();
        }
      });
    },

    // 审批修改状态确定
    planSubmit() {
      let params = {
        workOrderTypeId: this.workOrderTypeId,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
        contractId: this.contractId,
      };
      // console.log("params", params);
      let res = shangjiContractShangjiUpdateContract(params);
      res.then((res) => {
        Message.success(res.data);
        this.dblclickVisible = false;
        this.reload();
      });
    },
    // 双击事件
    dblclick(row) {
      this.workOrderTypeId = row.contractNumber;
      this.contractId = row.id;
      this.dblclickVisible = true;
      let params = {
        contractNumber: this.workOrderTypeId,
      };
      let res = shangjiContractShangjiSelectContract(params);
      res.then((res) => {
        this.planData = res.data.data;
        console.log("res", this.planData);
        res.data.data.forEach((v) => {
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    colorPlan(value) {
      // console.log(112233, value);
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },
    //删除
    del(row) {
      this.$confirm("是否确定删除当前合同?（不可恢复）！！！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params = {
            id: row.row.id,
          };
          let res = shangjiContractShangjiDelete(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.reload();
            } else {
              Message.error(res.data.message);
              this.reload();
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //重置刷新按钮
    reset() {
      this.type = null;
      this.businessType = null;
      this.companyName = null;
      this.createTime = null;
      this.getList();
    },
    //点击查找
    handleSearch() {
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        type: this.type,
        businessType: this.businessType,
        companyName: this.companyName,
        beginTime: this.createTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0])
          : null,
        endTime: this.createTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1])
          : null,
      };
      let res = shangjiContractShangjiSelect(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#contract {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .fstatusn {
    color: #909399;
  }
  .fstatus0 {
    color: #e6a23c;
  }
  .fstatus1 {
    color: #67c23a;
  }
  .fstatus2 {
    color: #f56c6c;
  }
  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }
  .plan-status1 {
    background-color: #e9e9eb;
  }
  .plan-status2 {
    background-color: #b3d8ff;
  }
  .plan-status3 {
    background-color: #e1f3d8;
  }
  .plan-status4 {
    background-color: #fde2e2;
  }
  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .btns {
    // text-align: right;
    display: flex;
  }
  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
}
</style>