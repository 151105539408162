<template>
  <el-card id="audit">
    <header>
      <h1>合同管理</h1>
    </header>
    <el-divider></el-divider>
    <main>
      <contract v-if="ifParentId != 7" />
      <sjyContract v-else />
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="新版合同管理" name="contract">
        </el-tab-pane>
        <el-tab-pane label="老版合同管理" name="old">
          <old />
        </el-tab-pane>
      </el-tabs> -->
    </main>
  </el-card>
</template>

<script>
import contract from "@/views/audit/contract.vue";
import sjyContract from "@/views/audit/sjyContract.vue";
// import old from "@/views/audit/old.vue";

export default {
  components: { contract, sjyContract },
  data() {
    return {
      ifParentId: null,
      activeName: "contract",
    };
  },
  created() {
    this.ifParentId = localStorage.getItem("parentId");
  },
};
</script>

<style lang="less" scoped>
#audit {
  header {
    h1 {
      font-weight: normal;
    }
  }
}
</style>