<template>
  <el-card id="contract">
    <header>
      <!-- <h1>合同管理--新版本</h1> -->
      <!-- <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>合同金额</span>
            <el-input
              v-model="contractMoney"
              placeholder="请输入合同金额"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户签约人</span>
            <el-input
              v-model="clientUser"
              placeholder="请输入客户签约人"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>公司签约人</span>
            <el-input
              v-model="companyUser"
              placeholder="请输入公司签约人"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户名称</span>
            <el-input
              v-model="clientName"
              placeholder="请输入客户名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>合同名称</span>
            <el-input
              v-model="contractName"
              placeholder="请输入合同名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span style="width: 16%">创建时间</span>
            <el-date-picker
              v-model="beginTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div> -->
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @row-dblclick="dblclick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="companyName"
          label="公司名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="行业类型"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="createUserName"
          label="创建人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="联系人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="telephone"
          label="电话"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="businessHandling"
          label="产品类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="businessType"
          label="业务类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="routeType"
          label="线路类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="numberFee"
          label="号码费"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="numberNum"
          label="号码数量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="combo"
          label="套餐(月)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seatsNumber"
          label="坐席数量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rateWithin"
          label="套餐内费率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rateExtra"
          label="套餐外费率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="comboRecharge"
          label="套餐外充值"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cooperationTime"
          label="合作周期(月)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="featureFee"
          label="功能费"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="feeTotal"
          label="费用合计"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="presented"
          label="赠送"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="installment"
          label="是否分期"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarke"
          label="备注"
          align="center"
        ></el-table-column>

        <el-table-column prop="auditStatus" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.auditStatus == 1">审核中</span>
            <span v-if="scope.row.auditStatus == 2">审核通过</span>
            <span v-if="scope.row.auditStatus == 3">审核未通过</span>
          </template>
        </el-table-column>
      </el-table>
    </main>

    <!-- 双击弹出框 -->
    <el-dialog title="审批 & 进度" :visible.sync="dblclickVisible">
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="colorPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="auditRemark"
              v-if="v.status == 2 && planId == getId"
            >
            </el-input>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              v-else
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="btns">
        <!-- <el-button type="success" @click="productSearch" style="width: 49%"
          >点击查看产品信息</el-button
        > -->
        <el-button type="primary" @click="planSubmit" style="width: 100%"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  shangjiContractShangjiRemindContract,
  shangjiContractShangjiSelectContract,
  shangjiContractShangjiUpdateContract,
} from "@/api/shangjiContract";
export default {
  data() {
    return {
      discountPriceList: [],
      cpData: [],
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,
      // // // // // // // // //
      beginTime: null,
      clientNameList: [],
      userList: [],
      contractForm: {},
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,

      beginTime: null,
      contractMoney: null,
      clientUser: null,
      companyUser: null,
      clientName: null,
      contractName: null,
      tableData: [], //表格内绑定的数据
    };
  },
  created() {
    this.getId = localStorage.getItem("id");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
  },
  methods: {
    // 审批修改状态确定
    planSubmit() {
      let params = {
        workOrderTypeId: this.workOrderTypeId,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
        contractId: this.contractId,
      };
      // console.log("params", params);
      let res = shangjiContractShangjiUpdateContract(params);
      res.then((res) => {
        Message.success(res.data);
        this.dblclickVisible = false;
        this.getList();
      });
    },
    // 双击事件
    dblclick(row) {
      this.workOrderTypeId = row.contractNumber;
      this.contractId = row.id;
      this.dblclickVisible = true;
      let params = {
        contractNumber: this.workOrderTypeId,
      };
      let res = shangjiContractShangjiSelectContract(params);
      res.then((res) => {
        this.planData = res.data.data;
        console.log("res", this.planData);
        res.data.data.forEach((v) => {
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    colorPlan(value) {
      // console.log(112233, value);
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 展示
    getList() {
      let params = {};
      let res = shangjiContractShangjiRemindContract(params);
      res.then((res) => {
        this.tableData = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#contract {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 45%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .fstatusn {
    color: #909399;
  }
  .fstatus0 {
    color: #e6a23c;
  }
  .fstatus1 {
    color: #67c23a;
  }
  .fstatus2 {
    color: #f56c6c;
  }
  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }
  .plan-status1 {
    background-color: #e9e9eb;
  }
  .plan-status2 {
    background-color: #b3d8ff;
  }
  .plan-status3 {
    background-color: #e1f3d8;
  }
  .plan-status4 {
    background-color: #fde2e2;
  }
  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .btns {
    // text-align: right;
    display: flex;
  }
  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
}
</style>