<template>
  <el-card id="contract">
    <main>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我发起的合同" name="list">
          <listAudit />
        </el-tab-pane>
        <el-tab-pane label="已审批合同" name="yet">
          <yetAudit />
        </el-tab-pane>
        <el-tab-pane label="待审批合同" name="not">
          <notAudit />
        </el-tab-pane>
      </el-tabs>
    </main>
  </el-card>
</template>

<script>
import listAudit from "@/views/audit/modules/listAudit.vue";
import yetAudit from "@/views/audit/modules/yetAudit.vue";
import notAudit from "@/views/audit/modules/notAudit.vue";

export default {
  components: { listAudit, yetAudit, notAudit },
  data() {
    return {
      activeName: "list",
    };
  },
};
</script>

<style lang="less" scoped>
#contract {
  header {
    h1 {
      font-weight: normal;
    }
  }
}
</style>