<template>
  <el-card id="contract">
    <header>
      <!-- <h1>合同管理--新版本</h1> -->
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>创建时间</span>
            <el-date-picker
              v-model="beginTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @row-dblclick="dblclick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractNumber"
          label="合同编号"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="companyId"
          label="公司ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientId"
          label="客户ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="companyUserId"
          label="公司签约人ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productId"
          label="产品ID"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="createUser"
          label="创建人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractName"
          label="合同名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientName"
          label="客户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientUser"
          label="客户签约人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="companyUser"
          label="公司签约人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractMoney"
          label="合同金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderDate"
          label="下单时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.orderDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="beginDate"
          label="开始时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.beginDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="结束时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.endDate) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注"
          align="center"
        ></el-table-column>

        <el-table-column prop="auditStatus" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.auditStatus == 1">审核中</span>
            <span v-if="scope.row.auditStatus == 2">审核通过</span>
            <span v-if="scope.row.auditStatus == 3">审核未通过</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="small"
              >编辑</el-button
            >
            <el-button type="danger" @click="del(scope)" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 编辑合同弹出框 -->
    <el-dialog title="编辑合同" :visible.sync="updateVisible" width="70%">
      <el-form ref="contractForm" :model="contractForm" label-width="100px">
        <div class="contract">
          <el-form-item label="合同名称">
            <el-input
              v-model="contractForm.contractName"
              placeholder="请输入合同名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-select
              v-model="contractForm.clientName"
              placeholder="请选择客户名称"
              @change="$forceUpdate()"
              filterable
            >
              <el-option
                v-for="(item, i) in clientNameList"
                :key="item.id"
                :label="item.realname"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同金额">
            <el-input-number
              v-model="contractForm.contractMoney"
              :precision="1"
              :step="1"
              :max="99999999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="订单时间">
            <el-date-picker
              v-model="contractForm.orderDate"
              type="datetime"
              placeholder="选择订单时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-date-picker
              v-model="contractForm.beginDate"
              type="datetime"
              placeholder="选择开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker
              v-model="contractForm.endDate"
              type="datetime"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公司签约人">
            <el-select
              v-model="contractForm.companyUser"
              placeholder="请选择签约人"
              filterable
            >
              <el-option
                v-for="(item, i) in userList"
                :key="item.id"
                :label="item.realname"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="contractForm.remark"
            placeholder="备注"
          ></el-input>
        </el-form-item>
        <!-- 从产品列表代入 售价&数量&折扣 -->
        <!-- 产品 -->
        <el-table
          :data="cpData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="productName"
            label="产品名字"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="standardPrice"
            label="产品价格"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="standardPrice"
            label="标准价格"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="unit"
            label="单位"
            align="center"
          ></el-table-column>
          <el-table-column prop="sellingPrice" label="售价" align="center">
            <template #default="scope">
              <el-input
                type="number"
                v-model="scope.row.sellingPrice"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="discountPrice" label="折扣" align="center">
            <template #default="scope">
              <el-select
                v-model="scope.row.discountPrice"
                placeholder="请选择折扣"
                filterable
              >
                <el-option
                  v-for="item in discountPriceList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量" align="center">
            <template #default="scope">
              <el-input type="number" v-model="scope.row.num"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="sumMoney"
            label="合计"
            align="center"
            :formatter="formatter"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="100"
            align="center"
            fixed="right"
          >
            <template #default="scope">
              <el-button type="primary" @click="delelt(scope.row)" size="small"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog title="审批 & 进度" :visible.sync="dblclickVisible">
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="colorPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="auditRemark"
              v-if="v.status == 2 && planId == getId"
            >
            </el-input>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              v-else
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="btns">
        <!-- <el-button type="success" @click="productSearch" style="width: 49%"
          >点击查看产品信息</el-button
        > -->
        <el-button type="primary" @click="planSubmit" style="width: 100%"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  contractSelect,
  contractDelete,
  contractUpdate,
  contractSelectContractWorkOrderType,
  contractUpdateContractOrderType,
  contractSelectContractPrice,
} from "@/api/contract";
import { phoneList } from "@/api/phone";
import { userList1 } from "@/api/user";
let token = localStorage.getItem("TOKEN");
export default {
  data() {
    return {
      discountPriceList: [
        {
          id: 0,
          title: "无",
          value: 1,
        },
        {
          id: 1,
          title: "一折",
          value: 0.1,
        },
        {
          id: 2,
          title: "二折",
          value: 0.2,
        },
        {
          id: 3,
          title: "三折",
          value: 0.3,
        },
        {
          id: 4,
          title: "四折",
          value: 0.4,
        },
        {
          id: 5,
          title: "五折",
          value: 0.5,
        },
        {
          id: 6,
          title: "六折",
          value: 0.6,
        },
        {
          id: 7,
          title: "七折",
          value: 0.7,
        },
        {
          id: 8,
          title: "八折",
          value: 0.8,
        },
        {
          id: 9,
          title: "九折",
          value: 0.9,
        },
      ],
      cpData: [],
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,
      // // // // // // // // //
      beginTime: null,
      clientNameList: [],
      userList: [],
      contractForm: {},
      updateVisible: false,
      beginDate: null,
      endDate: null,
      audit1Visible: false,
      audit2Visible: false,
      fid: "",
      renewMoney: 0,
      rejectVisibleTwo: "", // 驳回原因
      createTime: "",
      auditVisible: false,
      setAuditVisible: false,
      rejectVisible: false,
      myHeaders: {
        "X-Access-Token": token,
      },
      form: {
        phoneId: "",
        user_realname: "",
        active_phone: "",
        company: "",
        money: "",
        user_id: "",
      },
      formss: {},
      forms: {
        phoneId: "",
        remark: "",
        money: "",
        user_id: "",
      },
      id: "", // 号码id
      money: null, // 当前列数据金额
      value: null,
      status: "",
      remark: "", //备注（只传不回显）
      user_realname: "",
      active_phone: "",
      activePhone: "",
      company: "",
      roleName: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    // this.productList();
    this.clientList();
    this.fondUserList();
    this.form.company = this.$route.query.company;
    this.roleName = localStorage.getItem("roleName");
    this.user_realname = localStorage.getItem("user_realname");

    this.getId = localStorage.getItem("id");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
  },
  inject: ["reload"],
  methods: {
    // 合计的处理
    formatter(row, column) {
      // console.log("合计处理", row);
      let sum = row.sellingPrice * row.discountPrice * row.num;
      row.sumMoney = sum.toFixed(2);
      return sum.toFixed(2);
    },
    // 移除
    delelt(row) {
      this.$confirm("是否移除该产品", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.cpData.forEach((v, i) => {
            if (v.id == row.id) {
              this.cpData.splice(i, 1);
            }
            Message.success("已移除");
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 编辑
    update(row) {
      this.contractForm = { ...row.row };
      this.updateVisible = true;
      let params = {
        contractNumber: row.row.contractNumber,
      };
      let res = contractSelectContractPrice(params);
      res.then((res) => {
        console.log("表格数据", res);
        this.cpData = res.data.data;
      });
    },
    // 编辑确定
    updateSubmit() {
      let params = {
        contractName: this.contractForm.contractName,
        clientName: this.clientNameList[this.contractForm.clientName].realname,
        clientId: this.clientNameList[this.contractForm.clientName].id,
        companyName: this.contractForm.companyName,
        orderDate: this.contractForm.orderDate
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.contractForm.orderDate)
          : null,
        beginDate: this.contractForm.beginDate
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.contractForm.beginDate)
          : null,
        endDate: this.contractForm.endDate
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.contractForm.endDate)
          : null,
        contractMoney: this.contractForm.contractMoney,
        companyUser: this.userList[this.contractForm.companyUser].realname,
        companyUserId: this.userList[this.contractForm.companyUser].id,
        remark: this.contractForm.remark,
      };
      params.contractPriceList = this.cpData.map((v) => {
        return {
          productName: v.productName, // 产品名字
          unit: v.unit, // 单位
          standardPrice: v.procuctPrice, // 标准价格
          sellingPrice: v.sellingPrice, // 售价
          discountPrice: v.discountPrice, // 折扣
          num: v.num, // 数量
          sumMoney: v.sumMoney, // 合计
          id: v.id,
        };
      });
      // console.log("params", params);
      let res = contractUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.wrapVisible = false;
          this.reload();
        } else {
          Message.error(res.data.message);
          this.wrapVisible = false;
          this.reload();
        }
      });
    },
    // 客户列表的下拉数据
    clientList() {
      let params = {};
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params = {
          bsf: 1,
          page: this.page,
          size: this.size,
          activePhone: this.activePhone,
          userId: this.userId,
          depart_id: this.departId,
          company: this.company,
          createTime: this.createTime,
          changeTime: this.changeTime,
          getTime: this.getTime,
          choosePeople: this.choosePeople,
          customerScale: this.customerScale,
          industry: this.industry,
          address: this.address,
          customerSource: this.customerSource,
        };
      } else {
        params = {
          bsf: 1,
          page: this.page,
          size: this.size,
          industry: this.industry,
          address: this.address,
          userId: this.id1,
          activePhone: this.activePhone,
          company: this.company,
          createTime: this.createTime,
          changeTime: this.changeTime,
          getTime: this.getTime,
          choosePeople: this.choosePeople,
          customerScale: this.customerScale,
          customerSource: this.customerSource,
        };
      }
      let res = phoneList(params);
      res.then((res) => {
        this.clientNameList = [];
        res.data.data.forEach((v, i) => {
          this.clientNameList.push({ id: v.id, realname: v.customerPhone });
        });
      });
    },
    // 查询所有人员
    fondUserList() {
      let params = {};
      let res = userList1(params);
      res.then((res) => {
        this.userList = [];
        res.data.data.forEach((v, i) => {
          this.userList.push({
            id: v.user_id,
            realname: v.realname,
            obj: { id: v.user_id, realname: v.realname },
          });
        });
      });
    },

    // 审批修改状态确定
    planSubmit() {
      let params = {
        workOrderTypeId: this.workOrderTypeId,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
        contractId: this.contractId,
      };
      // console.log("params", params);
      let res = contractUpdateContractOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.dblclickVisible = false;
        this.reload();
      });
    },
    // 双击事件
    dblclick(row) {
      this.workOrderTypeId = row.contractNumber;
      this.contractId = row.id;
      this.dblclickVisible = true;
      let params = {
        contractNumber: this.workOrderTypeId,
      };
      let res = contractSelectContractWorkOrderType(params);
      res.then((res) => {
        this.planData = res.data.data;
        console.log("res", this.planData);
        res.data.data.forEach((v) => {
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    colorPlan(value) {
      // console.log(112233, value);
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },
    //删除
    del(row) {
      this.$confirm("是否确定删除当前合同?（不可恢复）！！！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params = {
            id: row.row.id,
          };
          let res = contractDelete(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.reload();
            } else {
              Message.error(res.data.message);
              this.reload();
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //重置刷新按钮
    reset() {
      this.beginTime = null;
      this.getList();
    },
    //点击查找
    handleSearch() {
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        beginDate: this.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.beginTime[0])
          : null,
        endDate: this.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.beginTime[1])
          : null,
      };
      let res = contractSelect(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#contract {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .fstatusn {
    color: #909399;
  }
  .fstatus0 {
    color: #e6a23c;
  }
  .fstatus1 {
    color: #67c23a;
  }
  .fstatus2 {
    color: #f56c6c;
  }
  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }
  .plan-status1 {
    background-color: #e9e9eb;
  }
  .plan-status2 {
    background-color: #b3d8ff;
  }
  .plan-status3 {
    background-color: #e1f3d8;
  }
  .plan-status4 {
    background-color: #fde2e2;
  }
  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .btns {
    // text-align: right;
    display: flex;
  }
  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
}
</style>